export default {
  init() {
    // JavaScript to be fired on the home page
    // var bar = document.querySelector( '.search-bar' ),
    // icon = bar.querySelector( '.icon-search' ),
    // input = bar.querySelector( 'input' );

    // icon.addEventListener( 'click' , function() {
    //     if ( icon.classList.contains( 'clicked' ) ) {
    //         icon.classList.remove( 'clicked' );
    //         input.style.width = '0';
    //         input.style.padding = '0 0';
    //     }
    //     else {
    //         icon.classList.add( 'clicked' );
    //         input.style.width = '330px';
    //         input.style.padding = '0 10px';
    //         input.focus();
    //     }
    // } );
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
