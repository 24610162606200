/* eslint-disable quotes */
import "select2";
import "flickity/dist/flickity.pkgd.js";
import "viewerjs/dist/viewer.min.js";
import "jquery-viewer/dist/jquery-viewer.min.js";
import "lightcase/src/js/lightcase.js";

export default {
  init() {
    bannerSlider();

    let $shopCats = $(".shop-categories");

    if ($shopCats.length > 0) {
      var cellAlignValue = "";

      if (matchMedia("screen and (max-width: 576px)").matches) {
        cellAlignValue = "center";
      } else {
        cellAlignValue = "left";
      }

      $shopCats.flickity({
        pageDots: false,
        cellAlign: cellAlignValue,
        wrapAround: true,
      });
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // Floor 1
    var $groundFloor = $('#map-1');

    $groundFloor.viewer({
      tooltip:0,
      toolbar: {
        zoomIn: {
          show: 1,
          size: 'large',
        },
        zoomOut: {
          show: 1,
          size: 'large',
        },
        oneToOne: 0,
        reset: 0,
        prev: 0,
        play: {
          show: 0,
          size: 'large',
        },
        next: 0,
        rotateLeft: 0,
        rotateRight: 0,
        flipHorizontal: 0,
        flipVertical: 0,
      },
      title: 0,
      viewed: function() {
        $groundFloor.viewer('zoomTo', 1.5);
        $groundFloor.viewer('navbar', 0);
      },
    });

    // Get the Viewer.js instance after initialized
    // $groundFloor.data('viewer');



    // Floor 2
    var $firstFloor = $('#map-2');

    $firstFloor.viewer({
      tooltip:0,
      toolbar: {
        zoomIn: {
          show: 1,
          size: 'large',
        },
        zoomOut: {
          show: 1,
          size: 'large',
        },
        oneToOne: 0,
        reset: 0,
        prev: 0,
        play: {
          show: 0,
          size: 'large',
        },
        next: 0,
        rotateLeft: 0,
        rotateRight: 0,
        flipHorizontal: 0,
        flipVertical: 0,
      },
      title: 0,
      viewed: function() {
        $firstFloor.viewer('zoomTo', 1.5);
      },
    });

    // Get the Viewer.js instance after initialized
    // $firstFloor.data('viewer');

    desktopSocials();
    addClassesToBlocks();


    // load more blog listing
    let currentPage = 1;
    $('#load-more').click(function () {
      let featuredPost = $('#featured-post').val();
      currentPage++;
      $.ajax({
        type: 'POST',
        url: '/wp-admin/admin-ajax.php',
        dataType: 'json',
        data: {
          action: 'post_load_more',
          paged: currentPage,
          featured: featuredPost,
        },
        success: function (res) {
          if(currentPage >= res.max) {
            $('#load-more').hide();
          }
          $('.recent-posts').append(res.html).show('slow');
        },
      });
    });

    // search drop down toggle class
    $(".header-container .search-icon-wrp .search-icon").click(function () {
      $(this).toggleClass("show");
      $(".header-container .search-wrp").toggleClass("show");
      $("body").toggleClass("search-open");
    });

    $(
      ".accordion-wrapper .left-column .store-item-outer-wrapper:first-child .store-content-wrapper .view-more .more-less-text"
    ).text("less");

    $(
      ".accordion-wrapper .left-column .store-item-outer-wrapper:first-child .store-content-wrapper .view-more"
    ).addClass("orange");

    $(".store-item-outer-wrapper").each(function () {
      $(this).click(function (e) {
        e.preventDefault();
        $(
          ".accordion-wrapper .left-column .store-item-outer-wrapper:first-child .store-content-wrapper .view-more"
        ).removeClass("orange");
        $(".view-more").removeClass("orange");
        $(".more-less-text").text("more");
        if ($(this).find(".times-wrapper").hasClass("show")) {
          $(this).find(".more-less-text").text("more");
          $(this).find(".view-more").removeClass("orange");
        } else {
          $(this).find(".more-less-text").text("less");
          $(this).find(".view-more").addClass("orange");
        }
      });
    });

    $(".wp-block-gallery").each(function () {
      if ($(this).children().length == "2") {
        $(this).addClass("two-col-gallery");
        $(this)
          .children()
          .find("a")
          .attr("data-rel", "lightcase:blogGalleryTwo:slideshow");
        $($(this).find(".wp-block-image")).each(function () {
          let $imageUrl = $(this).children().find("img").attr("src");
          $(this).find("a").attr("href", $imageUrl);
        });
      } else if ($(this).children().length == "3") {
        $(this).addClass("three-col-gallery");
        $(this)
          .children()
          .find("a")
          .attr("data-rel", "lightcase:blogGalleryThree:slideshow");
        $($(this).find(".wp-block-image")).each(function () {
          let $imageUrl = $(this).children().find("img").attr("src");
          $(this).find("a").attr("href", $imageUrl);
        });
      } else if ($(this).children().length == "4") {
        $(this).addClass("four-col-gallery");
        $(this)
          .children()
          .find("a")
          .attr("data-rel", "lightcase:blogGalleryFour:slideshow");
        $($(this).find(".wp-block-image")).each(function () {
          let $imageUrl = $(this).children().find("img").attr("src");
          $(this).find("a").attr("href", $imageUrl);
        });
      } else if ($(this).children().length > "4") {
        $(this).addClass("multiple-images");
        $(this)
          .children()
          .find("a")
          .attr("data-rel", "lightcase:blogGalleryFour:slideshow");
        $($(this).find(".wp-block-image")).each(function () {
          let $imageUrl = $(this).children().find("img").attr("src");
          $(this).find("a").attr("href", $imageUrl);
        });
      }
    });

    $(".contact-dropdown").select2();

    let $blogRelatedItems = $(".post-item-wrp");

    if ($blogRelatedItems.length > 0) {
      $blogRelatedItems.flickity({
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        adaptiveHeight: true,
        cellAlign: "left",
        watchCSS: true,
      });
    }

    // mega menu navigation
    $(".header-navigation .nav-wrp .nav-item").each(function () {
      $(this)
        .find("a")
        .click(function (e) {
          e.preventDefault();
          if ($(this).parent(".nav-item").hasClass("active")) {
            $(".nav-wrp .nav-item").removeClass("active");
            $(".mega-menu-block").removeClass("active");
            $("body").removeClass("mega-menu-open");
          } else {
            $(".nav-wrp .nav-item").removeClass("active");
            $(this).parent(".nav-item").addClass("active");
            $(".mega-menu-block").removeClass("active");
            $($(this).attr("href")).addClass("active");
            $("body").addClass("mega-menu-open");
          }
        });
    });

    // mega menu visit
    $(".sub-menu.visit .menu-item").each(function () {
      $(".sub-menu.visit .menu-item:first-child").addClass("active");
      $(".content-inner-wrp.visit:first-child").addClass("active");
      $(this)
        .find("a")
        .click(function (e) {
          e.preventDefault();
          $(".sub-menu.visit .menu-item").removeClass("active");
          $(this).parent(".menu-item").addClass("active");
          $(".content-inner-wrp.visit").removeClass("active");
          $($(this).attr("href")).addClass("active");
        });
    });

    // mega menu shop
    $(".sub-menu.cpt .menu-item").each(function () {
      $(".sub-menu.cpt .menu-item:first-child").addClass("active");
      $(".content-inner-wrp .store-cpt:first-child").addClass("active");
      $(this)
        .find("a")
        .click(function (e) {
          e.preventDefault();
          $(".sub-menu.cpt .menu-item").removeClass("active");
          $(this).parent(".menu-item").addClass("active");
          $(".content-inner-wrp .store-cpt").removeClass("active");
          $($(this).attr("href")).addClass("active");
        });
    });

    // mega menu eat-drink
    $(".sub-menu.cpt-cat .menu-item").each(function () {
      $(".sub-menu.cpt-cat .menu-item:first-child").addClass("active");
      $(".content-inner-wrp .store-cpt-cat:first-child").addClass("active");
      $(this)
        .find("a")
        .click(function (e) {
          e.preventDefault();
          $(".sub-menu.cpt-cat .menu-item").removeClass("active");
          $(this).parent(".menu-item").addClass("active");
          $(".content-inner-wrp .store-cpt-cat").removeClass("active");
          $($(this).attr("href")).addClass("active");
        });
    });

    // mega menu blog
    $(".sub-menu.blog .menu-item").each(function () {
      $(".sub-menu.blog .menu-item:first-child").addClass("active");
      $(".category-list-wrp .category-list:first-child").addClass("active");
      $(this)
        .find("a")
        .click(function (e) {
          e.preventDefault();
          $(".sub-menu.blog .menu-item").removeClass("active");
          $(this).parent(".menu-item").addClass("active");
          $(".category-list-wrp .category-list").removeClass("active");
          $($(this).attr("href")).addClass("active");
        });
    });

    $(".mega-menu-main .nav-item a").click(function () {
      if (!$(this).parent().hasClass("active")) {
        $(this).click();
      }

      $(".mega-menu-content-wrp").slideDown("slow");
      // scrolltop menu container on menu items clicked
      $(".mega-menu-content-inner-wrp").animate({ scrollTop: 0 }, 600);
    });

    // scrolltop menu container on sub menu items clicked
    jQuery('.mega-menu-main .mega-menu-content-wrp .mega-menu-block .sub-menu-item-wrp .sub-menu .menu-item a').click(function(){
      jQuery(".mega-menu-content-inner-wrp").animate({ scrollTop: 0 }, 600);
    });

    $("body").on("click", function () {
      $(".mega-menu-content-wrp").slideUp("slow");
      if ($(this).find(".nav-item").hasClass("active")) {
        $(".nav-wrp .nav-item").removeClass("active");
        $(".mega-menu-block").removeClass("active");
        $("body").removeClass("mega-menu-open");
      }
    });

    // disable body click within mega menu items
    $(".header-container").on("click", function (e) {
      e.stopPropagation();
    });

    // $(".mega-menu-main .menu-item a").click(function () {
    //   $(this).click();
    // });

    // Mobile Menu Accordion
    $(".mobile-menu-items .mobile-menu-items-inner > ul > li:has(ul)").addClass(
      "has-sub"
    );
    $(".mobile-menu-items .mobile-menu-items-inner ul > li > ul:has(li)").css(
      "display",
      "none"
    );

    $(".mobile-menu-items .mobile-menu-items-inner > ul > li > a").click(
      function () {
        var checkElement = $(this).next();

        $(".mobile-menu-items .mobile-menu-items-inner li").removeClass(
          "active"
        );
        $(this).closest("li").addClass("active");

        if (checkElement.is("ul") && checkElement.is(":visible")) {
          $(this).closest("li").removeClass("active");
          checkElement.slideUp("normal");
        }

        if (checkElement.is("ul") && !checkElement.is(":visible")) {
          $(
            ".mobile-menu-items .mobile-menu-items-inner ul ul:visible"
          ).slideUp("normal");
          checkElement.slideDown("normal");
        }

        if (checkElement.is("ul")) {
          return false;
        } else {
          return true;
        }
      }
    );

    // alert section close icon click
    $("body").addClass("has-alert");
    $(".alert-section .close-icon").click(function () {
      if ($("body").hasClass("has-alert")) {
        $(".alert-section").slideUp();
        $("body").removeClass("has-alert");
      }
    });

    $(".hamburger-menu .menu_btn").click(function () {
      $("body").toggleClass("mobile-menu-open");
    });

    jQuery("a[data-rel^=lightcase]").lightcase({
      slideshowAutoStart: false,
    });

    gallerySectionSlider()
  },
};

$(window).on("resize", function () {
  $('.gallery-section .gallery-wrapper, .cards-section .cards-wrp').flickity(
    'resize'
  );

  bannerSlider();
  desktopSocials();
});

$(window).on('load', function () {
  $('.gallery-section .gallery-wrapper, .cards-section .cards-wrp').flickity(
    'resize'
  );

  gallerySectionSlider();
});


function bannerSlider() {
  let $bannerItems = $(".banner-items");

  if ($bannerItems.length > 0) {
    var adaptiveHeightValue = "";

    if (matchMedia("screen and (max-width: 992px)").matches) {
      adaptiveHeightValue = true;
    } else {
      adaptiveHeightValue = false;
    }

    $bannerItems.flickity({
      pageDots: false,
      contain: true,
      prevNextButtons: true,
      adaptiveHeight: adaptiveHeightValue,
      cellAlign: "left",
    });
  }
}

// Gallery Section in Leasing
function gallerySectionSlider() {
  let $gallerySection = $(".gallery-section .gallery-wrapper");

  if ($gallerySection.length > 0) {
    $gallerySection.flickity({
      pageDots: true,
      contain: true,
      prevNextButtons: false,
      adaptiveHeight: false,
      wrapAround: true,
      cellAlign: "left",
    });
  }
}

function desktopSocials() {
  var socialWrapper = $('ul.social-icons').html();

  if($(".footer-menu-inner-wrapper > ul").find('ul.social-links-desktop').length == 0) {
    $(".footer-menu-inner-wrapper > ul").append(`<ul class="social-links-desktop d-none d-lg-flex">${socialWrapper}</ul>`);
  }
}

function addClassesToBlocks() {
  if ($('.block-element')) {
    $('.block-element').parent().removeClass('container').addClass('block-section-parent-wrp');
  }

  if ($('.block-section-parent-wrp')) {
    $('.block-section-parent-wrp').find('.banner-section').wrapAll('<div class="container">');
  }
}